var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading || _vm.mutationLoading,"spinner-variant":"primary"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.addOrganization}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm,"title":"Basic Information"}},[_c('validation-observer',{ref:"startupBasicInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Organization Name","label-for":"organization"}},[_c('validation-provider',{attrs:{"name":"Organization Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"organization","state":errors.length > 0 ? false:null,"placeholder":"Organization Name","type":"text"},model:{value:(_vm.organization.title),callback:function ($$v) {_vm.$set(_vm.organization, "title", $$v)},expression:"organization.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Designation","label-for":"designation"}},[_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"designation","state":errors.length > 0 ? false:null,"placeholder":"Your Designation","type":"text"},model:{value:(_vm.organization.designation),callback:function ($$v) {_vm.$set(_vm.organization, "designation", $$v)},expression:"organization.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"placeholder":"Organization Description","rows":"2"},model:{value:(_vm.organization.description),callback:function ($$v) {_vm.$set(_vm.organization, "description", $$v)},expression:"organization.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',{attrs:{"label":"Registered Name","label-for":"registered_title"}},[_c('validation-provider',{attrs:{"name":"Registered Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registered_title","state":errors.length > 0 ? false:null,"placeholder":"Registered Name","type":"text"},model:{value:(_vm.organization.registered_title),callback:function ($$v) {_vm.$set(_vm.organization, "registered_title", $$v)},expression:"organization.registered_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":"Type(s)","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type","options":['Company', 'Individual'],"state":errors.length > 0 ? false:null,"multiple":"","placeholder":"Select Type(s)"},model:{value:(_vm.organization.type),callback:function ($$v) {_vm.$set(_vm.organization, "type", $$v)},expression:"organization.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Official Email","label-for":"official_email"}},[_c('validation-provider',{attrs:{"name":"Official Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"official_email","state":errors.length > 0 ? false:null,"placeholder":"hello@yourbusiness.com","type":"text"},model:{value:(_vm.organization.official_email),callback:function ($$v) {_vm.$set(_vm.organization, "official_email", $$v)},expression:"organization.official_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"official_phone","state":errors.length > 0 ? false:null,"placeholder":"+91-1234567890","type":"text"},model:{value:(_vm.organization.official_phone),callback:function ($$v) {_vm.$set(_vm.organization, "official_phone", $$v)},expression:"organization.official_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false:null,"placeholder":"Organization Website URL","type":"text"},model:{value:(_vm.organization.url),callback:function ($$v) {_vm.$set(_vm.organization, "url", $$v)},expression:"organization.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Industry","label-for":"industry"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"industry","options":_vm.industries,"state":errors.length > 0 ? false:null,"placeholder":"Select Industry"},model:{value:(_vm.organization.industry),callback:function ($$v) {_vm.$set(_vm.organization, "industry", $$v)},expression:"organization.industry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lifecycle Stage","label-for":"organization_stage"}},[_c('validation-provider',{attrs:{"name":"Lifecycle Stage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"organization_stage","options":['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],"state":errors.length > 0 ? false:null,"placeholder":"Select Lifecycle Stage"},model:{value:(_vm.organization.startup_stage),callback:function ($$v) {_vm.$set(_vm.organization, "startup_stage", $$v)},expression:"organization.startup_stage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false:null,"placeholder":"City","type":"text"},model:{value:(_vm.organization.city),callback:function ($$v) {_vm.$set(_vm.organization, "city", $$v)},expression:"organization.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"State","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state","state":errors.length > 0 ? false:null,"placeholder":"Organization Website URL","type":"text"},model:{value:(_vm.organization.state),callback:function ($$v) {_vm.$set(_vm.organization, "state", $$v)},expression:"organization.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }